import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { DropdownStatusService, FromStatusService , ReturnServiceService, PassengerService, StateServiceService } from 'src/app/services/index';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { formatDate  } from '@angular/common';
import { DataService } from 'src/app/services/data.service';
import { Base64 } from 'js-base64';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-search-form',
  templateUrl: './search-form.component.html',
  styleUrls: ['./search-form.component.css'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({transform: 'translateY(-100%)'}),
        animate('2000ms ease-in', style({transform: 'translateY(0%)'}))
      ]),
      transition(':leave', [
        animate('2000ms ease-in', style({transform: 'translateY(-100%)'}))
      ])
    ])
  ]
})
export class SearchFormComponent implements OnInit,AfterViewInit {
  pickup='';
  dropoff='';
  reactiveForm: UntypedFormGroup;
  mobiletraveldate='';
  mobilereturndate='';
  mobilepassenger=''
  returning=false;
  formdata={};
  submitted = false;
  isOpen=false;
  passengerPlaceholder=" paseenger"
  totalpass:any
  passengersData:any;
  isReadOnly=false;
  classType:string
  classTypeSymbol:string
  bsInlineValue =formatDate(new Date(),"yyyy-MM-dd","en-KE");
  isRoundTrip = false; 
  placeholder: string = "Select Travellers and Class";

  constructor(
    private router: Router,
    private destinationService: DropdownStatusService,
    private fromService: FromStatusService,
    private passengerService:PassengerService,
    private returnservice: ReturnServiceService,
    private _fb: UntypedFormBuilder,
    private dataService : DataService,
    private stateService: StateServiceService
  ) {
    this.reactiveForm = _fb.group({
      pickup:[null, Validators.required],
      dropoff:[null, Validators.required],
      mobilepassenger:[null,Validators.required],
      mobiletraveldate:[this.bsInlineValue, Validators.required],
      mobilereturndate:[this.bsInlineValue]
    });
      this.destinationService.dropoffDateSource.subscribe(dvalue => {
        this.reactiveForm.controls['dropoff'].setValue(dvalue.iata);
        this.dropoff=dvalue;
      });
      this.fromService.valueMobileChange.subscribe(dvalue => {
        
        this.reactiveForm.controls['pickup'].setValue(dvalue.iata);
        this.pickup=dvalue;
      });
      this.dataService.travelValueMobile.subscribe(tvalue => {
        this.mobiletraveldate=tvalue;
        this.reactiveForm.controls['mobiletraveldate'].setValue(tvalue);
      });
      this.dataService.returndateMobileChange.subscribe(rvalue => {
          this.mobilereturndate=rvalue;
          this.reactiveForm.controls['mobilereturndate'].setValue(rvalue);
      });
      this.returnservice.currentValue.subscribe(rvalue => {
        this.returning=rvalue;
      });

      this.passengerService.passengerMobileValue.subscribe(passenger => {

        this.passengersData=passenger;
        this.totalpass = this.passengersData.adultpassenger+this.passengersData.childpassenger+this.passengersData.infantpassenger
        this.reactiveForm.patchValue({mobilepassenger: this.totalpass + '' + (this.totalpass > 1 ? ' Travellers,':' Traveller,') +  this.passengersData.classType})

      });
   }
    ngOnInit(): void {

      this.stateService.isRoundTrip$.subscribe(value => {
        this.isRoundTrip = value;
        this.returning =value;
      });

    }
    ngAfterViewInit() {
      this.returnservice.currentValue.subscribe(rvalue => {
        this.returning=rvalue;
      });
      if(this.totalpass > 0){
      }else {
        // this.reactiveForm.controls['mobilepassenger'].setValue(1 + ' passenger' );
        // this.reactiveForm.controls['mobileclasstype'].setValue('Economy' );

        this.passengersData ={
          'adultpassenger':1,
          'childpassenger':0,
          'infantpassenger':0,
          'class':"economy",
          'classType':"economy"
        }

      
        this.totalpass = this.passengersData.adultpassenger+this.passengersData.childpassenger+this.passengersData.infantpassenger
        this.reactiveForm.patchValue({mobilepassenger: this.totalpass + '' + (this.totalpass > 1 ? ' Travellers,':' Traveller,') +  this.passengersData.classType})


      }

    }

    get f() { return this.reactiveForm.controls; }
  destinationsClicked(){
    this.router.navigate(['/mobile-to'])
  }
  depaturesClicked(){
    this.router.navigate(['/mobile-from'])
  }
  depatureDateClicked(){
    this.router.navigate(['/depature-date'])
  }
  returnDateClicked(){
    this.router.navigate(['/return-date'])
  }

  passengerClicked(){
    this.router.navigate(['/mobile-passenger'])
  }
  isOneWayClicked(){
    this.returning =false;
    this.isRoundTrip =false;
    this.returnservice.changeValue(false);
  }
  isReturnTripClicked(){
    this.returning =true;
    this.isRoundTrip =true;
    this.returnservice.changeValue(true);
  }

  switchValues() {
    const currentPickup = this.reactiveForm.get('pickup').value;
    const currentDropoff = this.reactiveForm.get('dropoff').value;
  
    // Switch the values
    this.reactiveForm.get('pickup').setValue(currentDropoff);
    this.reactiveForm.get('dropoff').setValue(currentPickup);
  }


  setTripType(tripType: string) {
    if(tripType === 'roundtrip'){
      this.stateService.setRoundTrip(true);
    }
    this.isRoundTrip = tripType === 'roundtrip';

}

  cabinValue(ev){
    if(ev == 'Economy'){
      this.classType="Economy"
      this.classTypeSymbol='M'
    }
    if(ev == 'Business'){
      this.classType="Business"
      this.classTypeSymbol='C'
    }
    if(ev == 'Premium Economy'){
      this.classType="Premium Economy"
      this.classTypeSymbol='W'
    }
    if(ev == 'First Class'){
      this.classType="First Class"
      this.classTypeSymbol='F'
    }

  }

 
    submitForm() {
        this.submitted = true;
           // stop here if form is invalid
        if (this.reactiveForm.invalid ) {
            return;
        }
        let value = this.reactiveForm.value;

        this.formdata={
          "depature_city": value.pickup,
          "destination_city": value.dropoff,
          "departure_on":this.mobiletraveldate,
          "arrival_on": (this.isRoundTrip) ?  this.mobilereturndate : this.mobiletraveldate,
          "trip_type":(this.isRoundTrip) ? 'return':'oneway',
          "adult_count":this.passengersData.adultpassenger,
          "child_count":this.passengersData.childpassenger,
          "infant_count":this.passengersData.infantpassenger,
          "travel_class": this.passengersData.classType,
          "from_data": this.pickup,
          "destination_data":this.dropoff,
          "returning":this.isRoundTrip
        }

        let pdata =Base64.encode(JSON.stringify(this.formdata));
        this.dataService.changeMessage(pdata);
        this.router.navigate(['/booking'])

    }
}
