import { Component, OnInit, Input, Output, EventEmitter, HostListener, forwardRef, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { PassengerService } from 'src/app/services/passenger.service';

const noop = () => {
};

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => PassengersComponent),
  multi: true
};

@Component({
  selector: 'app-passengers',
  templateUrl: './passengers.component.html',
  styleUrls: ['./passengers.component.css'],
  animations: [
    trigger('dialog', [
      transition('void => *', [
        style({ transform: 'scale3d(.3, .3, .3)' }),
        animate(100)
      ]),
      transition('* => void', [
        animate(100, style({ transform: 'scale3d(.0, .0, .0)' }))
      ])
    ])
  ],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class PassengersComponent implements OnInit,ControlValueAccessor {


  adults: number = 1;
  children: number = 0;
  infants: number = 0;
  class: string = 'economy';
  maxChildren: number = 8;  // Initially allow maximum children
  maxInfants: number = 1;  // Initially allow maximum infants
  isOpen: boolean = false;
  placeholder: string = "Select Travellers and Class";
  isSelectedValue: boolean;
  key: string;
  isFocused: boolean;
  selectedClass: string = 'economy';

  @Input() selected: number;
  @Input() depature: string;
  @Input() className: string;
  @Input() isReadOnly = false;
  @Output() optSelect = new EventEmitter();
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  timer
  passegner:string;

  private wasInside = false;

  constructor(private passengerService:PassengerService,private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {

    this.updateLimits();
    if (this.selected) {
      this.placeholder = '';
      this.isOpen = false;
    }


    let passengerData = {
      'adultpassenger':this.adults,
      'childpassenger':this.children,
      'infantpassenger':this.infants,
      'class':this.selectedClass,
      'classType':this.selectedClass
    }

    this.passengerService.changePassengerValue(passengerData);


  }

  updateCounts(): void {
    this.cdr.markForCheck();  // Trigger change detection manually
  }

  updateLimits(): void {
    const totalSelected = this.adults + this.children + this.infants;
    const remaining = 9 - totalSelected;

    this.maxChildren = Math.min(remaining + this.children, 9 - this.adults);  // Children limit
    this.maxInfants = Math.min(this.adults, remaining + this.infants);  // Infants limit based on adults
  }
  // Call this method when the number of adults, children, or infants changes
  onCountChange(): void {
    this.updateLimits();
  }

  onAdultChange(): void {
    this.updateLimits();
    if (this.infants > this.adults) {
      this.infants = this.adults;  // Adjust infants to not exceed the number of adults
    }
  }

  onChildrenOrInfantChange(): void {
    this.updateLimits();
  }

  get travellersInput(): string {
    const travellersCount = this.adults + this.children + this.infants;
    const travellersText = travellersCount > 1 ? 'Travellers' : 'Traveller';
    const classDescription = this.capitalize(this.selectedClass);  // Ensure 'class' is capitalized appropriately
    return `${travellersCount} ${travellersText}, ${classDescription}`;
  }

  // Optionally, handle class changes
  onClassChange(): void {
    console.log("Selected class:", this.selectedClass);
    // Additional actions can be performed here
  }

    // Utility method to capitalize the first letter of the class
    private capitalize(s: string): string {
      return s.charAt(0).toUpperCase() + s.slice(1);
    }

  updateChildrenButtons() {
    this.maxChildren = 8 - this.adults; // adjust the maximum allowed children based on the number of adults
  }




  @HostListener('click')
  clickInside() {
    let text = "clicked inside";
    this.wasInside = true;
  }

  @HostListener('document:click')
  clickout() {
    console.log('cliccked outside')
    if (!this.wasInside) {
      if (this.adults > 0 && this.selectedClass !=''){
        let passengerData = {
          'adultpassenger':this.adults,
          'childpassenger':this.children,
          'infantpassenger':this.infants,
          'class':this.selectedClass,
          'classType':this.selectedClass
        }
        this.passengerService.changePassengerValue(passengerData);
        this.isOpen=false;
      }

      console.log('herevsdjhjsd')
    }
    this.wasInside = false;
    
  }

    // Angular method called when component content changes
    ngOnChanges(): void {
      this.updateMaxChildren();
    }
  
    // Updates the maximum number of children that can be selected
    updateMaxChildren(): void {
      this.maxChildren = Math.max(0, 9 - this.adults);
    }


  assignValue(){
    
  }

  setValue(location:any) { 
    let passengerData = {
      'adultpassenger':this.adults,
      'childpassenger':this.children,
      'infantpassenger':this.infants,
      'class':this.selectedClass,
      'classType':this.selectedClass
    }
    this.passengerService.changePassengerValue(passengerData);

   
  }


  public setSelectedoptoion(selectedOption){
    this.onChangeCallback(selectedOption);
    this.optSelect.emit(selectedOption);
  }


  /**
  * toggle the dropdown
  * @param {any} event object
  */
  toggle(e: any) {
    e.stopPropagation();
    this.setValue('');
    console.log('toggle clicked')


    // close all previously opened dropdowns, before open
    const allElems = document.querySelectorAll('.dropdown-wrapper');
    for (let i = 0; i < allElems.length; i++) {
      allElems[i].classList.remove('is-open');
    }
        this.isOpen = !this.isOpen;
        // if (this.totalpassenger > 0 && this.classType !=''){
        //   let passengerData ={
        //     'adultpassenger':this.adultpassenger,
        //     'childpassenger':this.childpassenger,
        //     'infantpassenger':this.infantpassenger,
        //     'class':this.classTypeSymbol,
        //     'classType':this.classType
        //   }
        //   this.passengerService.changePassengerValue(passengerData);
        // }

    
      // this.classType=''
    // this.serviceFrom.changeValue(this.isOpen);
    if (this.selected >= 0) {
    }
  }

  checkIfOpen(e: any){
    this.setValue('');
    // close all previously opened dropdowns, before open
    const allElems = document.querySelectorAll('.dropdown-wrapper');
    for (let i = 0; i < allElems.length; i++) {
      allElems[i].classList.remove('is-open');
    }
    this.isOpen=true;

  }



  /**
   * Method implemented from ControlValueAccessor and set default selected value
   * @param {*} obj
   * @memberof FromComponent
   */
  writeValue(obj: any): void {
    // let passengerData = {
    //   'adultpassenger':this.adults,
    //   'childpassenger':this.children,
    //   'infantpassenger':this.infants,
    //   'class':this.selectedClass,
    //   'classType':this.selectedClass
    // }
    // this.passengerService.changePassengerValue(passengerData);

    // console.log('sdsdsdsdsd')
   
  }

  // From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  setDisabledState?(isDisabled: boolean): void {


  }

  




}
