import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { retry } from 'rxjs/operators'
import  { environment } from '../../environments/environment'
const httpOptions = {
  headers: new HttpHeaders({
   'Content-Type':  'application/json',
   'Authorization':  'Api-Key '+ environment.api_key
  })
};

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(
    private http:HttpClient
  ) { }

  saveBooking(request:any){
    return this.http.post(environment.saveBooking,request,{
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization','Bearer '+ localStorage.getItem('fbindings'))
    });
  }


}
