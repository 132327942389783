
<div class="header-class">
    <h2 class="h2-class">Select Return Date </h2>
</div>
<div class="row">
    <div class="col-md-12">
        <mat-card class="demo-inline-calendar-card">
            <mat-calendar [(selected)]="selected" (selectedChange)="onDateSelected($event)"></mat-calendar>
          </mat-card>
    </div>
</div>

