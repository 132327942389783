
<div class="header-class">
    <h2 class="h2-class">Select Destination Airport </h2>
</div>

<div class="search-container">
    <svg class="search-icon" width="1em" height="1em" viewBox="0 0 24 24" fill="currentColor" class="svg-icon" style="margin-top: 4px; font-size: 2rem; color: grey;"><path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path></svg>
    <input class="mobile-input-hdhg" [formControl]="searchControl"  type="text" placeholder="Search for an airport">
</div>

<ul *ngFor="let option of filteredCities; let i = index"  class="stations-list">
    <li (click)="onSelect(option)">
        <div class="container-mob">
            <div class="icon-cis">
                <button mat-stroked-button color="accent"><i class="fa fa-clock" aria-hidden="true"></i> </button>
            </div>
            <div class="text _plr-10 txt-truncate body-md">
                <p class="first-paragraph body-md">
                    {{ option.name }}
                </p>
                <p class="second-paragraph body-sm">
                    {{ option.city }}, {{ option.country }}
                </p>
            </div>
        </div>
    </li>   
</ul>
<ng-container *ngIf="!isLoadingKiwi"> 
<div class="popular-stations-header">
    Recent Searches
</div>

<ul *ngFor="let search of recentSearches$ | async; let i = index"  class="stations-list">
    <li (click)="onSelect(search)">
        <div class="container-mob">
            <div class="icon-cis">
                <button mat-stroked-button color="accent"><i class="fa fa-clock" aria-hidden="true"></i> </button>
            </div>
            <div class="text _plr-10 txt-truncate body-md">
                <p class="first-paragraph body-md">
                    {{ search.name }}
                </p>
                <p class="second-paragraph body-sm">
                    {{ search.city }}, {{ search.country }}
                </p>
            </div>
        </div>
        
    </li>   
</ul>

<!-- <div class="popular-stations-header">
    Popular Airports
</div> -->
</ng-container>



<div *ngIf="isLoadingKiwi" class="loading-indicator">
    <mat-progress-bar mode="query"></mat-progress-bar>
  </div>
  