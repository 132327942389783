import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { retry } from 'rxjs/operators'
import  { environment } from '../../environments/environment'

const httpOptions = {
  headers: new HttpHeaders({
   'Authorization':  'Bearer '+ localStorage.getItem('fbindings')
  })
};

@Injectable({
  providedIn: 'root'
})
export class BookingService {
  constructor(
    private http:HttpClient
  ) { }


  onSearchFlights(request:any){


    let request_body = {
      'api_agent':'',
      'airline':'',
      'fly_to':request.dropoffname.code,
      'fly_from':request.pickupname.code,
      'date_from':request.traveldate,
      'date_to':request.traveldate,
      'return_from':(request.returning) ? request.returndate : request.traveldate,
      'return_to':(request.returning) ? request.returndate : request.traveldate,
      'stopovers':'2',
      'currency':'',
      'adults':request.adults,
      'children':request.children,
      'infants':request.infants,
      'trip_type':(request.returning) ? "round":"oneway",
    }

    return this.http.post(environment.searchFlights,request_body,{
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization','Bearer '+ localStorage.getItem('fbindings'))
    });
  }

  checkFlight(request:any){

    return this.http.post(environment.checkFlights,request,{
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization','Bearer '+ localStorage.getItem('fbindings'))
    });
  }

  getAirlines(){
    return this.http.get(`${environment.getAirlines}` ,httpOptions)
    .pipe(
      retry(3),
    );
  }



}
