import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentService, DataService } from '../services/index';
import { LocationStrategy } from '@angular/common';
import { Error } from '../models/index'
@Component({
  selector: 'app-review-pay',
  templateUrl: './review-pay.component.html',
  styleUrls: ['./review-pay.component.css']
})
export class ReviewPayComponent implements OnInit {

  paytotalfare:any;
  bookingdata:any;
  bookingdetails:any;
  return_trip:any;
  is_return=false;
  loading=false;
    isCard=false;
    isMpesa=false;
    isVooma=false;
  public isCollapsed = true;
  public isCollapsedVooma = true;
  public isCollapsedCard = true;
  paymentType:any;
  isError:boolean=false;
  errorMessage:any;
  res:any;
  api_error:Error;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private service:PaymentService,
    private dataService:DataService,
    private locationStrategy: LocationStrategy,
  ) { }

  ngOnInit(): void {
    this.isCollapsedCard=false
    this.isMpesa=true;
    window.scroll(0,0);
    this.preventBackButton();
    this.dataService.currentMessage.subscribe(message =>{
      if(!message){
        message = localStorage.getItem('review-buupass')
      }else{
        localStorage.setItem('review-buupass', message)
      }
      let encoded =JSON.parse(atob(message))
      this.bookingdata =  JSON.parse(atob(encoded.bookingdata));
      this.bookingdetails = JSON.parse(atob(encoded.bookingdetails));
      this.is_return =  encoded.is_return;
      if(this.is_return){
        this.return_trip =  JSON.parse(atob(encoded.returndata));
        this.paytotalfare=this.bookingdetails.totalfaree + this.return_trip.totalfaree ;
      }else {
        this.paytotalfare=this.bookingdetails.totalfaree;
      }
    });

    this.isCollapsed = false;
    this.isCollapsedCard=true
    this.isCollapsedVooma=true
    this.paymentType="Mpesa";
    this.isCard=false;
    this.isMpesa=true;
    this.isVooma=false;
  }
  ngAfterViewInit(): void {
    let top = document.getElementById('top');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
  }
  preventBackButton() {
    history.pushState(null, null, location.href);
    this.locationStrategy.onPopState(() => {
      history.pushState(null, null, location.href);
    })
  }
isCollapsedCardClicked(){
  this.isCollapsedCard = !this.isCollapsedCard
  this.isCollapsedVooma=true
  this.isCollapsed=true
  this.paymentType="Card";
  this.isCard=true;
  this.isMpesa=false;
  this.isVooma=false;
}
isCollapsedVoomaClicked(){
  this.isCollapsedVooma = !this.isCollapsedVooma
  this.isCollapsedCard=true
  this.isCollapsed=true
  this.paymentType="Vooma";
  this.isCard=false;
  this.isMpesa=false;
  this.isVooma=true;
}
isCollapsedClicked(){
  this.isCollapsed = !this.isCollapsed
  this.isCollapsedCard=false
  this.isCollapsedVooma=true
  this.paymentType="Mpesa";
  this.isCard=false;
  this.isMpesa=true;
  this.isVooma=false;
}

submitBooking(){
this.paymentType="Mpesa";
if(this.paymentType){
  let bookingData:any;
  if(this.is_return){
    this.bookingdata.payment_channel=this.paymentType
    this.return_trip.payment_channel=this.paymentType
    bookingData={
      "initial_trip":this.bookingdata,
      "return_trip":this.return_trip
    }
  }else{
    bookingData=this.bookingdata;
    this.bookingdata.payment_channel=this.paymentType
  }
  this.loading=true;

}
}




}
