import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PassengerService {
  public passengerValue = new BehaviorSubject<string>('');
  public passengerMobileValue = new BehaviorSubject<any>('');
  valueChange = this.passengerValue.asObservable();
  valueMobileChange = this.passengerMobileValue.asObservable();
  constructor() { }

  changePassengerValue(value: any) {
    this.passengerValue.next(value)
  }

  changeMobilePassengerValue(value: any) {
    this.passengerMobileValue.next(value)
  }
}
