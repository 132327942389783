import { BrowserModule } from '@angular/platform-browser';
import { NgModule,LOCALE_ID, isDevMode } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatInputModule} from '@angular/material/input';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { ToastrModule } from 'ngx-toastr';
import { MatNativeDateModule } from '@angular/material/core';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import {MatRadioModule} from '@angular/material/radio';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatRippleModule} from '@angular/material/core';
import {MatCardModule} from '@angular/material/card';
// RECOMMENDED
// <-- import check device module
import enke from '@angular/common/locales/en';
import { registerLocaleData } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
registerLocaleData(enke);
import { AppComponent } from './app.component';
import { HeadermainComponent } from './shared/headermain/headermain.component';
import { HeaderhomeComponent } from './shared/headerhome/headerhome.component';
import { FooterComponent } from './shared/footer/footer.component';
import { PaymentsComponent } from './payments/payments.component';
import { HomeComponent } from './home/home.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { SpinnerComponent } from './shared/spinner/spinner.component';
import { BookingComponent } from './booking/booking.component';
import { ResultsLoaderComponent } from './shared/results-loader/results-loader.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { NobusesComponent } from './nobuses/nobuses.component';
import { SuccessComponent } from './success/success.component';
import { CounterDirective } from './directives/counter.directive';
import { AboutusComponent } from './aboutus/aboutus.component';
import { ContactusComponent } from './contactus/contactus.component';
import { AutofocusDirective } from './directive/autofocus.directive';
import { DropdownComponent } from './shared/dropdown/dropdown.component';
import { FromComponent } from './shared/from/from.component';
import { SharedscrollstatusService } from './services/sharedscrollstatus.service';
import { DateSelectComponent } from './shared/date-select/date-select.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NavComponent } from './mobile/nav/nav.component';
import { SearchFormComponent } from './mobile/search-form/search-form.component';
import { HomeMobileComponent } from './mobile/home-mobile/home-mobile.component';
import { DepatureDateComponent } from './mobile/depature-date/depature-date.component';
import { ReturnDateComponent } from './mobile/return-date/return-date.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ReviewPayComponent } from './review-pay/review-pay.component';
import { LoginComponent } from './auth/login/login.component';
import { MyaccountComponent } from './myaccount/myaccount.component';
import { AccountHeaderComponent } from './shared/account-header/account-header.component';
import { MytripsComponent } from './mytrips/mytrips.component';
import { MobileFromComponent } from './mobile/mobile-from/mobile-from.component';
import { TownFilterPipe } from './pipes/town-filter.pipe';
import { MobileToComponent } from './mobile/mobile-to/mobile-to.component';
import { MynotificationsComponent } from './mynotifications/mynotifications.component';
import { PassengersComponent } from './shared/passengers/passengers.component';
import { TimeFormartPipe } from './pipes/time-formart.pipe';
import { MobilePassengerComponent } from './mobile/mobile-passenger/mobile-passenger.component';
import { VarDirectiveDirective } from './directives/var-directive.directive';
import { MinHourPipe } from './pipes/min-hour.pipe';
import { TimeDiffPipe } from './pipes/time-diff.pipe';
import { HotelsRedirectComponent } from './hotels-redirect/hotels-redirect.component';
import { MinReturnHourPipe } from './pipes/min-return-hour.pipe';

// Custom date format
export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'LL', // Moment Date Format
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@NgModule({
  declarations: [
    AppComponent,
    HeadermainComponent,
    HeaderhomeComponent,
    FooterComponent,
    PaymentsComponent,
    HomeComponent,
    NotFoundComponent,
    SpinnerComponent,
    BookingComponent,
    ResultsLoaderComponent,
    ConfirmComponent,
    NobusesComponent,
    SuccessComponent,
    CounterDirective,
    AboutusComponent,
    ContactusComponent,
    AutofocusDirective,
    DropdownComponent,
    FromComponent,
    DateSelectComponent,
    NavComponent,
    SearchFormComponent,
    HomeMobileComponent,
    DepatureDateComponent,
    ReturnDateComponent,
    ReviewPayComponent,
    LoginComponent,
    MyaccountComponent,
    AccountHeaderComponent,
    MytripsComponent,
    MobileFromComponent,
    TownFilterPipe,
    MobileToComponent,
    MynotificationsComponent,
    PassengersComponent,
    TimeFormartPipe,
    MobilePassengerComponent,
    VarDirectiveDirective,
    MinHourPipe,
    TimeDiffPipe,
    HotelsRedirectComponent,
    MinReturnHourPipe,
    
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    ScrollingModule,
    NgbModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatProgressBarModule,
    MatDatepickerModule,
    MatAutocompleteModule,
    MatInputModule,
    MatFormFieldModule,
    ToastrModule.forRoot(),
    MatNativeDateModule,
    MatButtonToggleModule,
    MatRadioModule,
    MatButtonModule,
    MatRippleModule,
    MatCardModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }],

  bootstrap: [AppComponent]
})
export class AppModule { }
