


   
<div class="card-mobile">
    <mat-card>
    <mat-card-header>
        <div class="trip-type">
            <button [ngClass]="{'selected': !isRoundTrip}" class="trip-button" (click)="setTripType('oneway')">One way</button>
            <button [ngClass]="{'selected': isRoundTrip}" class="trip-button" (click)="setTripType('roundtrip')">Round Trip</button>
        </div>
    </mat-card-header>
    <mat-card-content>
    <form [formGroup]="reactiveForm" (ngSubmit)="submitForm()" >
        <div class="input-wrapper">
            <i class="fa fa-solid fa-plane-departure  location-icon pin-icon"></i>
            <input name="pickup" formControlName="pickup"  (click)="depaturesClicked()" type="text" placeholder="Leaving from" class="bottom-border-input">
            <svg class="switch-icon" (click)="switchValues()" id="Group_287" data-name="Group 287" width="1em" height="1em" viewBox="0 0 36 36" class="svg-icon" style="font-size: 42px;"><path id="Path_734" data-name="Path 734" d="M30.728,5.272A18,18,0,0,0,5.272,30.728,18,18,0,0,0,30.728,5.272Zm-18.673,22.3L6.068,21.538l1.5-1.485,3.385,3.413V9.929H13.06V23.523l3.378-3.464,1.51,1.473ZM28.428,15.941,25.05,12.477V26.071H22.94V12.534l-3.385,3.413-1.5-1.485,5.987-6.037,5.893,6.043Z" transform="translate(0 0)" fill="currentColor" stroke="transparent"></path></svg>
            <span *ngIf="submitted && f.pickup.errors" [@slideInOut] class="invalid-select">Please enter a city or airport
                <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
              </span>
        </div>
        <div class="input-wrapper">
            <i class="fa fa-solid fa-plane-arrival fa-3xl location-icon"></i>
            <input name="dropoff" formControlName="dropoff"  (click)="destinationsClicked()" type="text" placeholder="Going To" class="bottom-border-input">
            <span *ngIf="submitted && f.dropoff.errors" [@slideInOut] class="invalid-select">Please enter a city or airport
                <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
              </span>
        </div>
        <div class="departure-text">Departure</div>
        <div class="input-wrapper">
            <i class="fa fa-solid fa-calendar fa-2x"></i>
            <input name="mobiletraveldate" formControlName="mobiletraveldate"  (click)="depatureDateClicked()" type="text" placeholder="Depature Date" class="bottom-border-input">
            <span *ngIf="submitted && f.mobiletraveldate.errors" [@slideInOut] class="invalid-select">Please select depature date
                <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
              </span>
        </div>
        <div *ngIf="isRoundTrip" class="return-trip">
            <div class="departure-text">Return</div>
            <div class="input-wrapper">
                <i class="fa fa-solid fa-calendar fa-2x"></i>
                <input name="mobilereturndate" formControlName="mobilereturndate"  (click)="returnDateClicked()" type="text" placeholder="Return Date" class="bottom-border-input">
                <span *ngIf="submitted && f.mobilereturndate.errors" [@slideInOut] class="invalid-select">Please select return date
                    <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                  </span>
            </div>

        </div>

        <div class="input-wrapper">
            <i class="fa fa-solid fa-2x fa-user"></i>
            <input name="mobilepassenger" formControlName="mobilepassenger"  (click)="passengerClicked()" type="text" [placeholder]="placeholder" class="bottom-border-input">
            <span *ngIf="submitted && f.mobilepassenger.errors" [@slideInOut] class="invalid-select">Please select depature date
                <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
              </span>
        </div>

        <button type="submit" class="full-width-btn">Search</button>
    </form>
</mat-card-content>
</mat-card>
</div>



