<!-- from.component.html -->
<mat-form-field appearance="fill" class="full-width m-autocomplete">
  <mat-label>{{ placeholder }}</mat-label>
  <input matInput
         [formControl]="control"
         [matAutocomplete]="auto"
         [readonly]="isReadOnly"
         [placeholder]="placeholder"
         aria-label="To"
         (focus)="onFocus()"
         (blur)="onBlur()">

  <mat-autocomplete   #auto="matAutocomplete" (optionSelected)="onSelection($event)" >
    <mat-option class="_pb-5" *ngFor="let option of citiesOptions$ | async" [value]="option">
      <div class="container-mob _mt-10">
        <div class="icon-cis">
            <button mat-stroked-button color="accent"><i class="fa fa-clock" aria-hidden="true"></i> </button>
        </div>
        <div class="text _plr-10 txt-truncate body-md wch-100">
            <p class="first-paragraph body-md">
                {{ option.name }}
            </p>
            <p class="second-paragraph body-sm">
                {{ option.city }}, {{ option.country }}
            </p>
        </div>
      </div>
    </mat-option>

    <ng-container *ngIf="!isLoadingKiwi"> 
    <mat-option *ngIf="(recentSearches$ | async)?.length > 0">
      <span class="search-header">Recent Searches</span> </mat-option>
    <mat-option class="_pb-5"  *ngFor="let option of recentSearches$ | async" [value]="option">
      <div class="container-mob _mt-10">
        <div class="icon-cis">
            <button mat-stroked-button color="accent"><i class="fa fa-clock" aria-hidden="true"></i> </button>
        </div>
        <div class="text _plr-10 txt-truncate body-md wch-100">
            <p class="first-paragraph body-md">
                {{ option.name }}
            </p>
            <p class="second-paragraph body-sm">
                {{ option.city }}, {{ option.country }}
            </p>
        </div>
      </div>
    </mat-option>
    <mat-option *ngIf="(recentSearches$ | async)?.length > 0">
      <hr> </mat-option>
    </ng-container>
  
    
     
  </mat-autocomplete>
</mat-form-field>

<div *ngIf="isLoadingKiwi" >
  <mat-card>
    <mat-card-content>
      <mat-progress-bar mode="query"></mat-progress-bar>
    </mat-card-content>
  </mat-card>
  

</div>
