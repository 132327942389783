import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FlightLocation } from '../interfaces';
@Injectable({
  providedIn: 'root'
})
export class DropdownStatusService {
  private statusSource = new BehaviorSubject<boolean>(false);
  private statusDateSource = new BehaviorSubject<boolean>(false);
  private destinationDateSource = new BehaviorSubject<FlightLocation[]>([]);
  public dropoffDateSource = new BehaviorSubject<any>('');
  statusValue = this.statusSource.asObservable();
  statusDateValue = this.statusDateSource.asObservable();
  deatinationValue = this.destinationDateSource.asObservable();
  dropoffValue = this.dropoffDateSource.asObservable();
  constructor() { }

  changeValue(value: boolean) {
    this.statusSource.next(value)
  }

  changeDateValue(value: boolean) {
    this.statusDateSource.next(value)
  }

  setDestination(value: []) {
    this.destinationDateSource.next(value)
  }
  setDropOff(value: string) {
    this.dropoffDateSource.next(value)
  }

}
