import { Component, OnInit } from '@angular/core';
import { TownsService } from 'src/app/services/towns.service';
import {Observable,interval } from 'rxjs';
import {debounceTime, distinctUntilChanged, map} from 'rxjs/operators';
import { Router } from '@angular/router';
@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.css']
})
export class AboutusComponent implements OnInit {

  options: Array<string>;
  optionsInit: Array<string>;
  location:string;
  constructor(
    private townService: TownsService,
    private router: Router
  ) { 
    this.townService.currentValue.subscribe(message => {
      this.options =message;
      this.optionsInit=message;

    })
  }

  ngOnInit() {

  }


  townSelect(town){

  }

  cancel() {
    this.router.navigateByUrl('/home');
  }

  search = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    map(term =>
      {  
        term.length < 1
      ?  this.options = this.optionsInit
      : this.options=this.options.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10)
        
      })
  )
  

}
