
<div #testDiv class="mother-div _mt-20">

    <div class="header-class">
        <h2 class="h2-class">Travellers & CLass </h2>
    </div>

<div class="container-fluid" style="margin-top:5px">
    <div class="row">
      <div >
        <p class="_pd-10" >
            <span class="body-md color_h" >Adults</span>
            <span class="body-sm pull-right"><small>12Yrs or Above</small></span>
        </p>
      </div>
      <div class="col-md-9">
        <mat-button-toggle-group name="adults" [(ngModel)]="adults" aria-label="Select number of adults" (change)="onAdultChange()">
          <mat-button-toggle class="custom-toggle" *ngFor="let num of [1,2,3,4,5,6,7,8,9]" [value]="num">
            {{ num }}
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row">
      <div >
        <p class="_pd-10" >
            <span class="body-md color_h" >Children</span>
            <span class="body-sm pull-right"><small>2-12 yrs</small></span>
        </p>
      </div>
      <div class="col-md-9">
        <mat-button-toggle-group name="children" [(ngModel)]="children" aria-label="Select number of children" (change)="onChildrenOrInfantChange()">
          <mat-button-toggle class="custom-toggle" *ngFor="let num of [0,1,2,3,4,5,6,7,8]" [value]="num" [disabled]="num > maxChildren">
            {{ num }}
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>

    <div class="row">
      <div >
        <p class="_pd-10" >
            <span class="body-md color_h" >Infants</span>
            <span class="body-sm pull-right"><small>0-2 yrs</small></span>
        </p>
      </div>
      <div class="col-md-9">
        <mat-button-toggle-group name="infant" [(ngModel)]="infants" aria-label="Select number of adults" (change)="onChildrenOrInfantChange()">
          <mat-button-toggle class="custom-toggle" *ngFor="let num of [0,1,2,3,4]" [value]="num" [disabled]="num > maxInfants">
            {{ num }}
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row _mt-10">
      <div class="col-md-12">
        <h5 class="color_h">Class</h5>
        <mat-radio-group aria-label="Select a class" [(ngModel)]="selectedClass">
          <mat-radio-button value="economy">Economy</mat-radio-button>
          <mat-radio-button value="premiumEconomy">Premium Economy</mat-radio-button>
          <mat-radio-button value="business">Business</mat-radio-button>
        </mat-radio-group>
      </div>
      
    </div>
              
  </div>

</div>
<div class="pass-footer">
    <div class="container">
        <div class="row">
            <div class="col-xs-8">
                <p class="text-left">{{travellersInput}}</p>
            </div>
            <div class="col-xs-4">
                <button type="button" (click)="donePassengers()" class="pass-done-btn pull-right" mat-flat-button color="primary">Done</button>

            </div>
        </div>
    </div>
</div>