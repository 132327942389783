import { Component, OnInit } from '@angular/core';
import { formatDate, PlatformLocation  } from '@angular/common';
import {Router} from "@angular/router"
import { DataService } from 'src/app/services';
import { Base64 } from 'js-base64';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor(
    private router:Router,
    private dataService: DataService
  ) { }

  ngOnInit() {
  }


  clickNairobiTl(){
    var traveldate = formatDate(new Date(),"yyyy-MM-dd","en-KE");

    let formdata={
      'pickup' : 'Nairobi',
      'dropoff' : 'Kitale',
      'pickupname' : 'Nairobi',
      'dropoffname' : 'Kitale',
      'traveldate' :  traveldate,
      'returndate': '',
      'returning' : false
    }

    this.getTopRoutes(formdata)


  }

  clickNairobiMb(){
    var traveldate = formatDate(new Date(),"yyyy-MM-dd","en-KE");

    let formdata={
      'pickup' : 'Nairobi',
      'dropoff' : 'Malaba',
      'pickupname' : 'Nairobi',
      'dropoffname' : 'Malaba',
      'traveldate' :  traveldate,
      'returndate': '',
      'returning' : false
    }

    this.getTopRoutes(formdata)

  }

  clickNairobiBg(){
    var traveldate = formatDate(new Date(),"yyyy-MM-dd","en-KE");

    let formdata={
      'pickup' : 'Nairobi',
      'dropoff' : 'Bungoma',
      'pickupname' : 'Nairobi',
      'dropoffname' : 'Bungoma',
      'traveldate' :  traveldate,
      'returndate': '',
      'returning' : false
    }

    
    this.getTopRoutes(formdata)
  }

  popularClick(data){


  let from ={}
    let to ={}

    switch(data) {
      case 1:
        from={code: 'NBO', name: 'Jomo Kenyatta International Airport - Kenya (NBO- Nairobi)'}
        to={code: 'MBA', name: 'Mombasa Moi International Airport'}
        break;
      case 2:
        from={code: 'NBO', name: 'Jomo Kenyatta International Airport - Kenya (NBO- Nairobi)'}
        to={code: 'UKA', name: 'Ukunda Airstrip Airport'}
        break;
      case 3:
        from={code: 'NBO', name: 'Jomo Kenyatta International Airport - Kenya (NBO- Nairobi)'}
        to={code: 'DXB', name: 'Dubai International Airport'} 
        break;
      case 4:
         from={code: 'NBO', name: 'Jomo Kenyatta International Airport - Kenya (NBO- Nairobi)'}
         to={code: 'LHR', name: 'London - Heathrow Airport '}

         break;
      default:
        from={code: 'NBO', name: 'Jomo Kenyatta International Airport - Kenya (NBO- Nairobi)'}
        to={code: 'AMS', name: 'Amsterdam - Amsterdam Airport Schipol'}
    }

    
    var today = new Date()
    var tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
    var traveldate = formatDate(tomorrow,"yyyy-MM-dd","en-KE");
    let sdata = {
    'adults': 1,
    'children': 0,
    'classType': "Economy",
    'dropoff': to,
    'dropoffname': to,
    'infants': 0,
    'pickup': from,
    'pickupname': from,
    'returndate': "1970-01-01",
    'returning': false,
    'selected_cabin': "M",
    'traveldate': traveldate
    }

    this.getTopRoutes(sdata)


  }

  getTopRoutes(sdata){
    let p=Base64.encode(JSON.stringify(sdata));
    this.dataService.changeMessage(p);
    this.router.navigate(['/booking'])

  
  }

}
