import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { retry } from 'rxjs/operators'
import  { environment } from '../../environments/environment'


const httpOptions = {
  headers: new HttpHeaders({
   'Authorization':  'Bearer '+ localStorage.getItem('fbindings')
  })
};
@Injectable({
  providedIn: 'root'
})
export class ConfirmService {

  constructor(
    private http:HttpClient
  ) { }

// new confirmation endpoint
  confirmV2(booking_id:any,data:any){
    return this.http.post(environment.confirmPayments  + booking_id,data,httpOptions)
    .pipe(
      retry(3), 
    );
}

// Old endpoint here
  // confirmV2(booking_id:any){
  //     return this.http.post(environment.confirmPayments  + booking_id,httpOptions)
  //     .pipe(
  //       retry(3), 
  //     );
  // }


  confirmBooking(data) {
    return this
            .http
            .post<any>(`${environment.confirmBooking}`,data,httpOptions);
  }

}
