import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError, of } from 'rxjs';
import { jwtDecode } from "jwt-decode";
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import  { environment } from '../../environments/environment'


@Injectable({
  providedIn: 'root'
})
export class ItineraryauthService {
  private tokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  private apiUrl = environment.apiUrl;
  private clientId = environment.itinerary_client_id;
  private clientSecret = environment.itinerary_client_secret_key;
  private readonly TOKEN_KEY = 'itinerary_auth_token';
  private readonly REFRESH_TOKEN_KEY = 'itinierary_auth_refresh_token';
  constructor(private http: HttpClient) {}

  setRefreshToken(token: any): void {
    localStorage.setItem(this.REFRESH_TOKEN_KEY, token);
  }

  getRefreshToken(): string | null {
    return localStorage.getItem(this.REFRESH_TOKEN_KEY);
  }

  removeRefreshToken(): void {
    localStorage.removeItem(this.REFRESH_TOKEN_KEY);
  }

  setToken(token: any): void {
    localStorage.setItem(this.TOKEN_KEY, token);
  }

  removeToken(): void {
    localStorage.removeItem(this.TOKEN_KEY);
  }

  private generateToken(): Observable<string | any> {
    const tokenUrl = `${environment.getTokenCred}`;
    const body = { client_id: this.clientId, client_secret: this.clientSecret };
    console.log('generating Token')
    return this.http.post<any>(tokenUrl, body).pipe(
      map(response => {
        console.log('generating Token response')
        console.log(response)
        this.setToken(response.access_token)
        this.setRefreshToken(response.refresh_token)
        return of(response.access_token)
      }),
      catchError(error => {
        console.error('Error generating token:', error);
        return throwError('Failed to generate token');
      })
    );
  }


  getToken(): Observable<string | null  > {
    const token = localStorage.getItem(this.TOKEN_KEY);
    if (token) {
      console.log(token)
      return this.checkTokenExpiration(token).pipe(
        switchMap((isValid) => {
          if (!isValid) {
            return this.generateToken();
          } else {
            return of(token);
          }
        })
      );
    } else {
      console.log("generateToken")
      return this.generateToken();
    }
  }



  private refreshToken(): Observable<string> {
    // Implement token refresh logic here
    // This method should refresh the token by making a request to the token endpoint
    // and updating the tokenSubject with the new token
    // Example:
    // const refreshTokenUrl = 'your_refresh_token_endpoint';
    // return this.http.post<any>(refreshTokenUrl, { refreshToken }).pipe(
    //   map(response => response.access_token),
    //   tap(token => this.tokenSubject.next(token)),
    //   catchError(error => {
    //     console.error('Error refreshing token:', error);
    //     return throwError('Failed to refresh token');
    //   })
    // );
    return this.generateToken(); // Placeholder for demonstration (use actual refresh logic)
  }

  private checkTokenExpiration(token:string): Observable<boolean | any> {
    const decodedToken: any = jwtDecode(token?? "");
    if (!decodedToken.exp) {
      return of(false); //No expiry date found in token
    }
    const expiryTimestamp = decodedToken.exp * 1000; // Convert to milliseconds
    const currentTimestamp = new Date().getTime();

    return of(expiryTimestamp > currentTimestamp);
    //return of(true);
  }

  getData(): Observable<any> {
    return this.getToken().pipe(
      switchMap(token => {
        if (token) {
          const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
          return this.http.get<any>(this.apiUrl, { headers }).pipe(
            catchError(error => {
              console.error('Error fetching data:', error);
              return throwError('Failed to fetch data');
            })
          );
        } else {
          return throwError('No valid token available');
        }
      })
    );
  }
}
