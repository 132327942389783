import { Component, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { PassengerService } from 'src/app/services/passenger.service';

@Component({
  selector: 'app-mobile-passenger',
  templateUrl: './mobile-passenger.component.html',
  styleUrls: ['./mobile-passenger.component.css']
})
export class MobilePassengerComponent implements OnInit {
  passegner:string;
  inputnumber = 0;
  childpassenger=0;
  adultpassenger=1
  infantpassenger=0
  totalpassenger=0;
  classEconomy:boolean;
  classBusiness:boolean;
  classPremium:boolean;
  classFirstClass:boolean;
  classType='';
  classTypeSymbol='';

  adults: number = 1;
  children: number = 0;
  infants: number = 0;
  class: string = 'economy';
  maxChildren: number = 8;  // Initially allow maximum children
  maxInfants: number = 1;  // Initially allow maximum infants
  isOpen: boolean = false;
  placeholder: string = "Select Travellers and Class";
  isSelectedValue: boolean;
  key: string;
  isFocused: boolean;
  selectedClass: string = 'economy';
  minDate:Date

  isError=false
  constructor(
    private passengerService:PassengerService,
    private cdr: ChangeDetectorRef,
    private router: Router) { }

  ngOnInit(): void {

    this.updateLimits();

    this.classEconomy=true;
    this.adultpassenger=1
    let passengerData = {
      'adultpassenger':this.adults,
      'childpassenger':this.children,
      'infantpassenger':this.infants,
      'class':this.selectedClass,
      'classType':this.selectedClass
    }
    this.totalpassenger=1
    this.classType=this.selectedClass
    this.passengerService.changeMobilePassengerValue(passengerData);
    this.minDate = new Date();

  }



  updateCounts(): void {
    this.cdr.markForCheck();  // Trigger change detection manually
  }

  updateLimits(): void {
    const totalSelected = this.adults + this.children + this.infants;
    const remaining = 9 - totalSelected;

    this.maxChildren = Math.min(remaining + this.children, 9 - this.adults);  // Children limit
    this.maxInfants = Math.min(this.adults, remaining + this.infants);  // Infants limit based on adults
  }
  // Call this method when the number of adults, children, or infants changes
  onCountChange(): void {
    this.updateLimits();
  }

  onAdultChange(): void {
    this.updateLimits();
    if (this.infants > this.adults) {
      this.infants = this.adults;  // Adjust infants to not exceed the number of adults
    }
  }

  onChildrenOrInfantChange(): void {
    this.updateLimits();
  }

  get travellersInput(): string {
    const travellersCount = this.adults + this.children + this.infants;
    const travellersText = travellersCount > 1 ? 'Travellers' : 'Traveller';
    const classDescription = this.capitalize(this.selectedClass);  // Ensure 'class' is capitalized appropriately
    return `${travellersCount} ${travellersText}, ${classDescription}`;
  }

  // Optionally, handle class changes
  onClassChange(): void {
    // Additional actions can be performed here
  }

    // Utility method to capitalize the first letter of the class
    private capitalize(s: string): string {
      return s.charAt(0).toUpperCase() + s.slice(1);
    }

  updateChildrenButtons() {
    this.maxChildren = 8 - this.adults; // adjust the maximum allowed children based on the number of adults
  }


  donePassengers(){
    if (this.totalpassenger > 0){
      let passengerData = {
        'adultpassenger':this.adults,
        'childpassenger':this.children,
        'infantpassenger':this.infants,
        'class':this.selectedClass,
        'classType':this.selectedClass
      }
      this.passengerService.changeMobilePassengerValue(passengerData);
      this.router.navigateByUrl('/home');
    }else{
      this.isError=true
    }
  }

  clickout() {
      if (this.adults > 0 && this.selectedClass !=''){
        let passengerData = {
          'adultpassenger':this.adults,
          'childpassenger':this.children,
          'infantpassenger':this.infants,
          'class':this.selectedClass,
          'classType':this.selectedClass
        }
        this.passengerService.changeMobilePassengerValue(passengerData);

      }    
    
  }

}
