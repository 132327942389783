import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.css']
})
export class SuccessComponent implements OnInit {
  sub:any;
  ticketDetails:any;
  rawTicketDetails:any;
  flightData:any;
  bookingData:any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      let data= params['id'];
      let booking=JSON.parse(atob(data));
      this.ticketDetails=booking.data.data;
      this.rawTicketDetails = booking;
      this.bookingData = this.rawTicketDetails.data

    });
  }

}
