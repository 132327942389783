import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { trigger, transition, animate, style } from '@angular/animations'
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl, FormControl } from '@angular/forms';
import {Destinations } from '../models/index'
import {formatDateToYYYYMMDD,DataService, FlightSearchInterface, FromStatusService, DropdownStatusService, ItineraryauthService } from '../services/index'
import { formatDate  } from '@angular/common';
import {Router} from "@angular/router"
import { PassengerService } from '../services/passenger.service'
import flightDestinations from '../../assets/data/destinations.json';
import { Base64 } from 'js-base64';
import { Observable } from 'rxjs/internal/Observable';

import { BehaviorSubject, combineLatest } from 'rxjs';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({transform: 'translateY(-100%)'}),
        animate('2000ms ease-in', style({transform: 'translateY(0%)'}))
      ]),
      transition(':leave', [
        animate('2000ms ease-in', style({transform: 'translateY(-100%)'}))
      ])
    ])
  ]

})
export class HomeComponent implements OnInit  {
  myControl = new FormControl();
  tokenObj = {
    client_id: "arH2uK954XepkYhjsSjxlQ",
    client_secret: "f8OkK2VsSYgRDe92k5XwQaFs_64qGhnC9nndt006cD0",
    client_name: "M-pesaApp"
  };
  bsInlineValue = new Date();
  reactiveForm: UntypedFormGroup;
  fromPlaceholder="Enter airport";
  toPlaceholder="Enter airport";
  passengerPlaceholder=" paseenger"
  destinationLoading=false;
  pickup='';
  dropoff='';
  traveldate='';
  daterange='';
  destinations: Destinations[] = [];
  destination: any = {};
  res: any = {};
  today=new Date();
  bsValue = new Date();
  public pickplaceholder: string = 'Depature';
  public destplaceholder: string = 'Destination';
  public historyHeading: string = 'Recently selected';
  keyword = 'name';
  isClicked=false;
  isPickupClicked=false;
  returning=false;
  tripType;
  isReadOnly=false;
  types= [];
  //traveldate:any;
  data = [];
  msg = '';
  formdata={};
  radioflag:any
  passengersData:any;
  totalpassenger:any;
  submitted = false;
  loading: boolean;
  flag: any;
  radio_button_value = 1
  form = new UntypedFormGroup({
    traveldate: new UntypedFormControl(new Date()),
  });

  fromData:any
  destinationData:any

  defaultImage="assets/img/cities/placeholder.jpg";

  mombasa="assets/img/cities/mombasa.jpg";
  nairobi="assets/img/cities/nairobi.jpg";
  kisumu="assets/img/cities/kisumu.jpg";
  kampala="assets/img/cities/kampala.jpg";
  dar="assets/img/cities/dar.jpg";
  bungoma="assets/img/cities/bungoma.jpg";
  eldoret="assets/img/cities/eldoret.jpg";
  destinations$: Observable<any>;
  @ViewChild('stickyForm') menuElement: ElementRef;
  sticky: boolean = false;
  isOpen=false;
  elementPosition: any;
   formBuilder: UntypedFormBuilder
   minDate: Date;
   returnMinDate: Date;

   // Travel Duqa
   sampleData = [
    {
        name: "Jomo Kenyatta International",
        city: "Nairobi",
        country: "Kenya",
        iata: "NBO"
    },
    {
        name: "Nairobi Wilson",
        city: "Nairobi",
        country: "Kenya",
        iata: "WIL"
    }
]





  constructor(
    private dataService : DataService,
    private router:Router,
    private _fb: UntypedFormBuilder,
    private passengerService:PassengerService,
    private fromService:FromStatusService,
    private destinationService: DropdownStatusService,
    private auth:ItineraryauthService
        ) {
          
          this.reactiveForm = this._fb.group({
            pickup:[null, Validators.required],
            dropoff:[null, Validators.required],
            passenger:[null,Validators.required],
            daterange:[formatDate(new Date(),"yyyy-MM-dd","en")+':One Way',Validators.required],
            traveldate:[new Date(), Validators.required],
            returndate:[null, null]
          });

      
          this.minDate = new Date();
          this.returnMinDate = new Date();  // Today's date
          this.returnMinDate = this.addDays(this.minDate, 1); 
          this.passengerService.passengerValue.subscribe(passenger => {
            this.passengersData=passenger;
            this.totalpassenger = this.passengersData.adultpassenger+this.passengersData.childpassenger+this.passengersData.infantpassenger
            
            this.reactiveForm.patchValue({passenger: this.totalpassenger + ' Passenger,' +  this.passengersData.classType})
          
          });

    }
    

    addDays(date: Date, days: number): Date {
      let result = new Date(date);  // Create a new Date object to avoid modifying the original date
      result.setDate(result.getDate() + days);
      return result;
    }

  

  ngOnInit(): void {
    this.subscribeToFromValue();
    this.subscribeToDestinationValue()
    this.getTokenInit();
  }

 
  getTokenInit(){
    this.auth.getToken().subscribe(
      accessToken => {},
      error => {}
    );
  }

  private subscribeToFromValue() {
    this.fromService.fromValue.subscribe(from => {
       this.fromData = from
      this.reactiveForm.get('pickup').setValue(this.fromData.iata);
    });
  }

  private subscribeToDestinationValue() {
    this.destinationService.deatinationValue.subscribe(dest => {
      this.destinationData =  dest
      this.reactiveForm.get('dropoff').setValue(this.destinationData.iata);
      
    });
  }

  onTravelDateChange = (event) =>{
    this.returnMinDate = this.addDays(event.value, 1); 
  }


     get f() { return this.reactiveForm.controls; }

  onFocused(e){
    this.isClicked=true;
  }

  returnClicked =() =>{
    this.returning=true;
    this.isOpen=true;
  }
  onRadioChange(type:string){
    if(type=='return'){
      this.returning=true;
      this.reactiveForm.controls.returndate.setValidators([Validators.required]);
      this.reactiveForm.controls.returndate.updateValueAndValidity();
      // this.reactiveForm  = this.formBuilder.group({
      //   returndate: ['', Validators.required],
      // });
    }else{
      this.returning=false;
      this.reactiveForm.controls.returndate.setErrors(null);
      this.reactiveForm.controls.returndate.clearValidators();
      this.reactiveForm.controls.returndate.updateValueAndValidity();
    }
 
  }

  closeReturn(){
    this.returning=false;
  }

  onClosed(e){
    this.isClicked=false;
  }

  onPickupFocused(e){
    this.isClicked=true;
    this.isPickupClicked=true;

  }
  onPickupClosed(e){
    this.isClicked=false;
    this.isPickupClicked=false;
  }

  // when a city is clicked
  clickCity(data){
    let from:any ={}
    let to:any ={}

    switch(data) {
      case 1:
        from={
          name: "Jomo Kenyatta International",
          city: "Nairobi",
          country: "Kenya",
          iata: "NBO"
      }
        to={
          name: "Mombasa Moi International Airport",
          city: "Mombasa",
          country: "Kenya",
          iata: "MBA"
      }
        break;
      case 2:
        from={
          name: "Jomo Kenyatta International",
          city: "Nairobi",
          country: "Kenya",
          iata: "NBO"
      }
        to={
          name: "Kisumu Airport",
          city: "Kisumu",
          country: "Kenya",
          iata: "KIS"
      }
        break;
      case 3:
        from={
          name: "Jomo Kenyatta International",
          city: "Nairobi",
          country: "Kenya",
          iata: "NBO"
      }
        to={
          name: "Ukunda Airstrip",
          city: "Ukunda",
          country: "Kenya",
          iata: "UKA"
      }
        break;
      case 4:
         from={
          name: "Jomo Kenyatta International",
          city: "Nairobi",
          country: "Kenya",
          iata: "NBO"
      }
         to={
          name: "Eldoret International Airport",
          city: "Eldoret",
          country: "Kenya",
          iata: "EDL"
      }
      case 5:
        from={
          name: "Jomo Kenyatta International",
          city: "Nairobi",
          country: "Kenya",
          iata: "NBO"
      }
        to={
          name: "Amsterdam Airport Schiphol",
          city: "Amsterdam",
          country: "Netherlands",
          iata: "AMS"
      }
        break;
      case 6:
        from={
          name: "Jomo Kenyatta International",
          city: "Nairobi",
          country: "Kenya",
          iata: "NBO"
      }
        to={
          name: "London Heathrow Airport",
          city: "London",
          country: "United Kingdom",
          iata: "LHR"
      }
        break;
      case 7:
        from={
          name: "Jomo Kenyatta International",
          city: "Nairobi",
          country: "Kenya",
          iata: "NBO"
      }
        to={
          name: "John F Kennedy International Airport",
          city: "New York",
          country: "United States",
          iata: "JFK"
      }
        break;
      case 8:
         from={
          name: "Jomo Kenyatta International",
          city: "Nairobi",
          country: "Kenya",
          iata: "NBO"
      }
         to={
          name: "Leonardo da Vinci\u2013Fiumicino Airport",
          city: "Rome",
          country: "Italy",
          iata: "FCO"
      }
         break;
      default:
        from={
          name: "Jomo Kenyatta International",
          city: "Nairobi",
          country: "Kenya",
          iata: "NBO"
      }
        to={
          name: "Amsterdam Airport Schiphol",
          city: "Amsterdam",
          country: "Netherlands",
          iata: "AMS"
      }
    }
    
    var today = new Date()
    var tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
    var traveldate = formatDate(tomorrow,"yyyy-MM-dd","en-KE");


    let sdata={
      "depature_city": from.iata,
      "destination_city": to.iata,
      "departure_on":traveldate,
      "arrival_on":  traveldate,
      "trip_type":'oneway',
      "adult_count":1,
      "child_count":0,
      "infant_count":0,
      "travel_class": 'economy',
      "from_data": from,
      "destination_data":to,
      "returning":false
    }

    let p=Base64.encode(JSON.stringify(sdata));
    this.dataService.changeMessage(p);
    this.router.navigate(['/booking'])



  }

  // Function to check if a date string is valid
isValidDate(dateString: string): boolean {
  const date = new Date(dateString);
  return !isNaN(date.getTime());  // getTime() returns NaN if date is invalid
}

  submitForm() {
    // check if return
      if (this.reactiveForm.value.returndate && this.isValidDate(this.reactiveForm.value.returndate)) {
        this.returning =true;
      } else {
        this.returning =false;
      }
    
    // if(this.reactiveForm.value.returndate == null && !this.returning ){
    //   this.returning =false;
    //   this.isOpen=false;
    // }

      this.submitted = true;
      // stop here if form is invalid
      if (this.reactiveForm.invalid ) {
          return;
      }

      let value = this.reactiveForm.value;
      // var traveldate = formatDateToYYYYMMDD(value.traveldate)
      // var returndate = formatDateToYYYYMMDD(value.returndate)
      var traveldate = formatDate(value.traveldate,"yyyy-MM-dd","en-KE");
      var returndate = formatDate(value.returndate,"yyyy-MM-dd","en-KE");
      

      value.traveldate=traveldate;
      if(this.returning){
        value.returndate=returndate;
      }


    this.formdata={
      "depature_city": value.pickup,
      "destination_city": value.dropoff,
      "departure_on":traveldate,
      "arrival_on": (this.returning) ?  returndate : traveldate,
      "trip_type":(this.returning) ? 'return':'oneway',
      "adult_count":this.passengersData.adultpassenger,
      "child_count":this.passengersData.childpassenger,
      "infant_count":this.passengersData.infantpassenger,
      "travel_class": this.passengersData.class,
      "from_data": this.fromData,
      "destination_data":this.destinationData,
      "returning":this.returning
    }

    let p=Base64.encode(JSON.stringify(this.formdata));
    this.dataService.changeMessage(p);
    this.router.navigate(['/booking'])
  }


}
