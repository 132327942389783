import { Component, Output, EventEmitter, Input, ViewChild, OnInit  } from '@angular/core';
import { City } from 'src/app/models/index';
import { TownsService } from 'src/app/services/towns.service';
import { Router } from '@angular/router';
import { FromStatusService } from 'src/app/services/from-status.service';
import flightDestinations from '../../../assets/data/destinations.json';
import { FormControl } from '@angular/forms';
import { FlightSearchInterface, ItineraryServiceService } from 'src/app/services';
import { BehaviorSubject, Observable, catchError, debounceTime, distinctUntilChanged, filter, finalize, map, of, switchMap, tap } from 'rxjs';
@Component({
  selector: 'app-mobile-from',
  templateUrl: './mobile-from.component.html',
  styleUrls: ['./mobile-from.component.css']
})
export class MobileFromComponent implements OnInit {
  cities:any;
  selectedCity: City;
  searchControl = new FormControl();
  
  @Input() isReadOnly: boolean = false;
  @Input() placeholder: string = 'Search for city';
  citiesMobileOptions$: Observable<FlightSearchInterface[]>;
  private onChange: (value: string) => void = () => {};
  private onTouched: () => void = () => {};
  isLoadingKiwi = false;
  private recentSearchesSubject = new BehaviorSubject<FlightSearchInterface[]>([]);
  recentSearches$: Observable<FlightSearchInterface[]> = this.recentSearchesSubject.asObservable();
  private skipNextSearch = false;


  public flightCities:{name:string, code:string}[] = flightDestinations;
  filteredCities: any[];
  constructor(
    private router: Router,
    private itineraryService: ItineraryServiceService,
    private fromService:FromStatusService,
  ) {
    this.loadRecentSearches();
    this.setupSearch();
   }
  ngOnInit(): void {
    const recentSearches = this.loadRecentSearches();
    this.recentSearchesSubject.next(recentSearches);


  }


  setupSearch() {
  this.searchControl.valueChanges.pipe(
      debounceTime(200),  // Waits for 200ms pause in typing
      filter(value => typeof value === 'string'),  // Ensures the input is a string
      map(value => value.trim()),  // Trims whitespace
      distinctUntilChanged(),  // Ensures only unique values are processed
      tap(() => {
        this.isLoadingKiwi = true;  // Sets loading state before the search
      }),
      switchMap(value => {
        if (value.length < 3) {
          this.isLoadingKiwi = false;
          return of([]);  // Returns an empty array if less than 3 characters
        }
        if (this.skipNextSearch) {
          this.isLoadingKiwi = false;
          return of([]);
        } else {
          return this.search(value).pipe(
            finalize(() => {
              this.isLoadingKiwi = false;
            })
          );
        }
      }),
      catchError(error => {
        this.isLoadingKiwi = false;  // Ensures loading state is reset on error
        return of([]);
      })
    ).subscribe(filteredCities => {
      this.filteredCities = filteredCities
      // You can assign the result to a variable or state here if needed
      // this.filteredCities = filteredCities;
    });
  }

  saveRecentSearch(search: FlightSearchInterface) {
    let recentSearches = this.loadRecentSearches();
    // Remove duplicates (based on iata code)
    recentSearches = recentSearches.filter(item => item.iata !== search.iata);
    recentSearches = recentSearches.reverse(); 
    // Add new search at the beginning and keep only top 5
    recentSearches = [search, ...recentSearches].slice(0, 5);

    localStorage.setItem('recentSearches', JSON.stringify(recentSearches));
    this.recentSearchesSubject.next(recentSearches);
  }

  loadRecentSearches() {
    const storedSearches = localStorage.getItem('recentSearches');
    return storedSearches ? JSON.parse(storedSearches) : [];
  }

  search(value: string): Observable<FlightSearchInterface[]> {
    this.isLoadingKiwi = true; // Show loading indicator
    return this.itineraryService.getFlights({'channel': 'flights', 'city_query': value}).pipe(
      map(response => this.convertObjectToArray(response)),
      catchError(error => {
        console.error('Error fetching data: ', error);
        this.isLoadingKiwi = false; // Hide loading indicator on error
        return of([]);
      })
    );
  }

  convertObjectToArray(response: any): FlightSearchInterface[] {
    return Object.keys(response).map(key => ({
      ...response[key],
      name: `${response[key].city} - ${response[key].name}`,
      iata: response[key].iata,
      city: response[key].city,
      country: response[key].country
    }));
  }

  cancel() {
    this.router.navigateByUrl('/home');
  }
  onSelect(city: any): void {
    this.selectedCity = city;
    this.onChange(city.iata);
    this.fromService.changeSelectedMobileValue(city)
    this.onTouched();
    this.skipNextSearch = true;
    this.saveRecentSearch(city); // Set the flag to skip the next search
    this.router.navigateByUrl('/home');
  }



  onBlur(): void {
    this.searchControl.markAsTouched();
  }

  onFocus(): void {
    this.onChange('')
    this.searchControl.markAsUntouched();
  }

}
