import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, tap, catchError, map, startWith, filter, finalize } from 'rxjs/operators';
import { FlightSearchInterface, ItineraryServiceService, DropdownStatusService } from '../../services';

const noop = () => {
};
export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DropdownComponent),
  multi: true
};

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DropdownComponent),
    multi: true
  }]
})
export class DropdownComponent implements OnInit, ControlValueAccessor {
  @Input() options: FlightSearchInterface[] = [];
  @Input() placeholder: string = 'Enter location';
  @Input() isReadOnly: boolean = false;
  control = new FormControl();
  isLoadingKiwi = false;
  citiesOptions$: Observable<FlightSearchInterface[]>;
  private onChange: (value: string) => void = () => {};
  private onTouched: () => void = () => {};
  private skipNextSearch = false;
  private recentSearchesSubject = new BehaviorSubject<FlightSearchInterface[]>([]);
  recentSearches$: Observable<FlightSearchInterface[]> = this.recentSearchesSubject.asObservable();


  constructor(
    private itineraryService: ItineraryServiceService,
  private dropdownService:DropdownStatusService) {
    this.loadRecentSearches();
  }

  ngOnInit() {
    const recentSearches = this.loadRecentSearches();
    this.recentSearchesSubject.next(recentSearches);

    this.citiesOptions$ = this.control.valueChanges.pipe(
      debounceTime(200),  // Wait for 200ms pause in typing
      filter(value => typeof value === 'string'),  // Ensure the value is a string
      map(value => value.trim()),  // Trim the whitespace
      distinctUntilChanged(),  // Only continue if the value has changed
      tap(() => this.isLoadingKiwi = true),  // Set loading to true right before the switchMap
      switchMap(value => {
        if (value.length < 3) {
          // Do not emit any options if under 3 characters
          this.isLoadingKiwi = false;
          return of([]);
        }
        if (this.skipNextSearch) {
          this.isLoadingKiwi = false;
          return of([]);
        } else {
          return this.search(value).pipe(
            finalize(() => this.isLoadingKiwi = false)  // Ensure loading indicator is turned off after search
          );
        }
      }),
      catchError(error => {
        console.error('Error fetching data: ', error);
        this.isLoadingKiwi = false;  // Ensure loading indicator is turned off on error
        return of([]);
      })
    );

  }

  saveRecentSearch(search: FlightSearchInterface) {
    let recentSearches = this.loadRecentSearches();
  
    // Remove duplicates (based on iata code)
    recentSearches = recentSearches.filter(item => item.iata !== search.iata);
  
    recentSearches = recentSearches.reverse(); 
    // Add new search at the beginning and keep only top 5
    recentSearches = [search, ...recentSearches].slice(0, 5);
  
 
  
    localStorage.setItem('recentSearchesTo', JSON.stringify(recentSearches));
    this.recentSearchesSubject.next(recentSearches);
  }

  loadRecentSearches() {
    const storedSearches = localStorage.getItem('recentSearchesTo');
    return storedSearches ? JSON.parse(storedSearches) : [];
  }

  search(value: string): Observable<FlightSearchInterface[]> {
    this.isLoadingKiwi = true; // Show loading indicator
    return this.itineraryService.getFlights({'channel': 'flights', 'city_query': value}).pipe(
      map(response => this.convertObjectToArray(response)),
      catchError(error => {
        console.error('Error fetching data: ', error);
        this.isLoadingKiwi = false; // Hide loading indicator on error
        return of([]);
      })
    );
  }

  convertObjectToArray(response: any): FlightSearchInterface[] {
    return Object.keys(response).map(key => ({
      ...response[key],
      name: `${response[key].city} - ${response[key].name}`,
      iata: response[key].iata,
      city: response[key].city,
      country: response[key].country
    }));
  }


  writeValue(obj: any): void {
    this.control.setValue(obj, { emitEvent: false }); // Prevent triggering valueChanges when setting value programmatically
  }

  registerOnChange(fn: any): void {
    // this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    // this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.control.disable();
    } else {
      this.control.enable();
    }
  }


  onSelection(event: any): void {
    const selectedValue = event.option.value;
    this.writeValue(selectedValue.iata);
    this.onTouched();
    this.dropdownService.setDestination(selectedValue)
    this.skipNextSearch = true; // Set the flag to skip the next search
    this.saveRecentSearch(selectedValue);
  }



  clearInput(): void {
    this.control.setValue('');
  }

  onBlur(): void {
    this.control.markAsTouched();
  }

  onFocus(): void {
    this.onChange('')
    this.control.markAsUntouched();
  }
  
}
