<div class=" hidden-md hidden-lg ">
    <app-nav></app-nav>
</div>
<div class="visible-lg visible-md">
  <app-headerhome ></app-headerhome>
</div>
<div class="progress-cntr-wrpr">
        <div class="progress-cntr">
            <div class="progress-tracker-cntr transparent">
                <div class="progress-tracker">
                    <div class="progress-item first ">
                        <div class="item-success">
                            <div class="progess-item-label">Booking Passenger details</div>
                        </div>
                    </div>
                    <div class="progress-item ">
                        <div class="item-success">
                            <div class="progess-item-label">Confirm Payment</div>
                        </div>
                    </div>
                    <div class="progress-item last current">
                        <div class="item-wrapper">
                            <div class="progess-item-label">Ticket</div>
                        </div>
                    </div>
                    <div class="progress-tracker-completed" style="width: 0%;"></div>
                </div>
            </div>
        </div>
    </div>
<div class="theme-page-section  theme-page-section-gray searchFont">
        <div class="container">
          <div class="row">
            <div class="col-md-6 col-md-offset-4">
              <div class="theme-payment-success">
                <div class="theme-payment-success-header">
                  <h1 class="theme-payment-success-title"> Booking confirmed.</h1>
                </div>
                <div class="theme-payment-success-box">
                  <div class="row theme-payment-success-summary">
                    <p>Your payment has been received successfully.</p>
                    <p>Your booking <b>{{bookingData.booking_reference}}</b> has been reserved.</p>
                    <p>Booking confirmation is being processed, and you will receive an email with ticket details shortly.</p>
                    <p>Have a great trip!</p>
                    <p>Don’t forget to reach out to us via 0715763362 or help@buupass.com if you have any queries or concerns.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
 </div>


<app-footer></app-footer>
