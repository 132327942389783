
<div class="row ">
    <div (click)="toggle($event)" class="col-md-12 " [ngClass]="{'is-open':isOpen,'input-opened':isOpen}">
      <mat-form-field class="example-full-width">
        <mat-label>Travellers & Class</mat-label>
        <input class="truncate" matInput [placeholder]="placeholder" [value]="travellersInput">
      </mat-form-field>
    </div>
</div>
<div  class="mother-div">
<div class="row" [@dialog]>
    <div class="col-md-12" >
      <ul class="dropdown-options" [ngClass]="{'input-opened-options':isOpen}" >
        <div class="container-fluid" style="margin-top:5px">
            <h3 class="theme-search-results-sidebar-section-title color_h-m ">Travellers</h3>

            <div class="row">
              <div class="col-md-3">
                <h6 class="color_h">Adults</h6>
                <p><small>12Yrs or Above</small></p>
              </div>
              <div class="col-md-9">
                <mat-button-toggle-group name="adults" [(ngModel)]="adults" aria-label="Select number of adults" (change)="onAdultChange()">
                  <mat-button-toggle class="custom-toggle" *ngFor="let num of [1,2,3,4,5,6,7,8,9]" [value]="num">
                    {{ num }}
                  </mat-button-toggle>
                </mat-button-toggle-group>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                <h6 class="color_h">Children</h6>
                <small>2-12 yrs</small>
              </div>
              <div class="col-md-9">
                <mat-button-toggle-group name="children" [(ngModel)]="children" aria-label="Select number of children" (change)="onChildrenOrInfantChange()">
                  <mat-button-toggle class="custom-toggle" *ngFor="let num of [0,1,2,3,4,5,6,7,8]" [value]="num" [disabled]="num > maxChildren">
                    {{ num }}
                  </mat-button-toggle>
                </mat-button-toggle-group>
              </div>
            </div>

            <div class="row">
              <div class="col-md-3">
                <h6 class="color_h">Infants</h6>
                <small>0-2 yrs</small>
              </div>
              <div class="col-md-9">
                <mat-button-toggle-group name="infant" [(ngModel)]="infants" aria-label="Select number of adults" (change)="onChildrenOrInfantChange()">
                  <mat-button-toggle class="custom-toggle" *ngFor="let num of [0,1,2,3,4]" [value]="num" [disabled]="num > maxInfants">
                    {{ num }}
                  </mat-button-toggle>
                </mat-button-toggle-group>
              </div>
            </div>
            <div class="row _mt-10">
              <div class="col-md-12">
                <h5 class="color_h">Class</h5>
                <mat-radio-group aria-label="Select a class" [(ngModel)]="selectedClass">
                  <mat-radio-button value="economy">Economy</mat-radio-button>
                  <mat-radio-button value="premiumEconomy">Premium Economy</mat-radio-button>
                  <mat-radio-button value="business">Business</mat-radio-button>
                </mat-radio-group>
              </div>
              
            </div>
            <div class="row _mt-10">
              <div class="col-md-12">
                <div class="pull-right">

                  <button type="button" (click)="clickout()" class="pass-done-btn" mat-flat-button color="primary">Done</button>
                </div>

              </div>
            </div>
           
                      
          </div>
      </ul>
    </div>
</div>
</div>