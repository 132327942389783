<div class="bus-listing-page ">
        <div class="page-content ">
                <div class="bus-listing-rows">
                    <div class="no-results">
                        <div class="sr-row row ride--booked js-ride--booked">
                            <div class="col-md-12">
                                    <div class="img-holder text-center"><img src="assets/img/images/flight.webp"  height="150"></div>
                                    <div class="text">

                                        <div class="content text-center _mt-40">
                                            <h4 class="heading">Opps! we are sorry...</h4>
                                            <p>There are no flights available on this date. Try another date.</p>
                                        </div>
                                </div>
                            </div>
                          </div>
                    </div>
                </div>
        </div>
    </div>
