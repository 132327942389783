
    <div class="theme-footer" id="mainFooter">
        <div class="container _ph-mob-0">
          <div class="row row-eq-height row-mob-full" data-gutter="60">
            <div class="col-md-2">
              <div class="theme-footer-section theme-footer-">
                <a class=" _mb-mob-30" href="#">
                  <img src="assets/img/company1/logo-name.png" alt="buupass.com" title="Image Title"/>
                </a>
                <div class="theme-footer-brand-text _mt-20">
                  <a target="_blank" href="https://www.instagram.com/buupass/?hl=en" >
                    <svg class="Icon__StyledIcon-sc-1pnzn3g-0 icon-cl" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet"><path d="M7.8 2h8.4C19.4 2 22 4.6 22 7.8v8.4a5.8 5.8 0 01-5.8 5.8H7.8C4.6 22 2 19.4 2 16.2V7.8A5.8 5.8 0 017.8 2zm-.2 2A3.6 3.6 0 004 7.6v8.8C4 18.39 5.61 20 7.6 20h8.8a3.6 3.6 0 003.6-3.6V7.6C20 5.61 18.39 4 16.4 4H7.6zm9.65 1.5a1.25 1.25 0 110 2.5 1.25 1.25 0 010-2.5zM12 7a5 5 0 110 10 5 5 0 010-10zm0 2a3 3 0 100 6 3 3 0 000-6z"></path></svg>
                  </a>
                  <a target="_blank" href="https://twitter.com/buupass?lang=en" >
                      <svg class="Icon__StyledIcon-sc-1pnzn3g-0 icon-cl" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet"><path d="M17.466 6.177c.6-.05 1.38-.38 1.839-.61l.446-.241c-.262.698-.617 1.245-1.165 1.66-.12.091-.241.216-.393.274.779.001 1.421-.345 2.032-.533a5.628 5.628 0 01-1.218 1.385l-.56.438c.01.813-.012 1.587-.167 2.27-.897 3.968-3.272 6.662-7.033 7.816-1.35.414-3.533.584-5.08.206-.767-.188-1.46-.4-2.111-.68a8.31 8.31 0 01-1.016-.515l-.315-.19c.349.011.757.106 1.147.044.353-.057.7-.042 1.025-.112.812-.175 1.533-.407 2.154-.765.302-.174.759-.378.973-.628a3.083 3.083 0 01-1.069-.19c-1.162-.403-1.837-1.145-2.277-2.26.351.038 1.365.127 1.603-.069-.444-.024-.87-.277-1.174-.464-.934-.576-1.696-1.541-1.69-3.027l.368.172c.234.097.473.15.753.206.118.024.354.093.49.043-.198-.207-.492-.345-.675-.567-.599-.734-1.16-1.863-.805-3.207.09-.341.233-.643.385-.92.087.152.243.258.342.378.306.374.684.71 1.068 1.006 1.312 1.01 2.492 1.63 4.388 2.09a6.996 6.996 0 001.612.206c-.161-.463-.11-1.212.018-1.66.32-1.126 1.014-1.938 2.032-2.373a4.39 4.39 0 01.797-.24l.438-.052c1.39-.024 2.13.475 2.838 1.11z"></path></svg>
                  </a>
                  <a target="_blank" href="https://www.facebook.com/BuuPass/" >
                    <svg class="Icon__StyledIcon-sc-1pnzn3g-0 icon-cl" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet"><path d="M5 3h14a2 2 0 012 2v14a2 2 0 01-2 2H5a2 2 0 01-2-2V5a2 2 0 012-2zm13 2h-2.5A3.5 3.5 0 0012 8.5V11h-2v3h2v7h3v-7h3v-3h-3V9a1 1 0 011-1h2V5z"></path></svg>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-md-10">
              <div class="row">
                <div class="col-md-4">
                  <div class="theme-footer-section theme-footer-">
                    <h5 class="theme-footer-section-title">The Company</h5>
                    <ul class="theme-footer-section-list">
                      <li>
                        <a routerLink="/about-us.html">About the Company</a>
                      </li>
                      <li>
                        <a routerLink="/contact-us.html">Contact us</a>
                      </li>
                      <li>
                        <a target="_blank" rel="noreferrer" href="https://buupass.com/BuuPass_Website_Terms_of_Use.pdf ">Terms & Conditions </a>
                      </li>
                      <li>
                        <a target="_blank" rel="noreferrer" href="https://blog.buupass.com/">Blog</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="theme-footer-section theme-footer-">
                    <h5 class="theme-footer-section-title">Popular Flights</h5>
                    <ul class="theme-footer-section-list">
                      <li >
                        <a class="change-city" (click)="popularClick(1)" >Nairobi - Mombasa</a>
                      </li>
                      <li >
                        <a class="change-city" (click)="popularClick(2)" >Nairobi - Diani</a>
                      </li>
                      <li >
                        <a class="change-city" (click)="popularClick(3)" >Nairobi - Dubai</a>
                      </li>
                      <li >
                        <a class="change-city" (click)="popularClick(4)" >Nairobi - London</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="theme-footer-section theme-footer-">
                    <h5 class="theme-footer-section-title">Our products</h5>
                    <ul class="theme-footer-section-list">
                      <li>
                        <a href="#">BMS</a>
                      </li>
                      <li>
                        <a href="#">Car hire</a>
                      </li>
                      <li>
                        <a href="#">Parcel</a>
                      </li>
                      <li>
                        <a href="#">Booking Api`s</a>
                      </li>
                    </ul>
                  </div>
                </div>
            
              </div>
            </div>

          </div>
        </div>
      </div>
