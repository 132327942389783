import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeFormart'
})
export class TimeFormartPipe implements PipeTransform {

  transform(value: string): string {
    let n = value.split(":")
    let hr =n[0]
    let min =n[1]
    return hr +':'+ min;
  }

}
