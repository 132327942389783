import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import {FlightLocation} from './../interfaces/index'




@Injectable({
  providedIn: 'root'
})
export class FromStatusService {
  public statusSource = new BehaviorSubject<boolean>(false);
  private statusDateSource = new BehaviorSubject<boolean>(false);
  public fromValue = new BehaviorSubject<FlightLocation[]>([]);
  private fromValueMobile = new BehaviorSubject<any>('');

  statusValue = this.statusSource.asObservable();
  statusDateValue = this.statusDateSource.asObservable();
  valueChange = this.fromValue.asObservable();
  valueMobileChange = this.fromValueMobile.asObservable();

  constructor() { }

  changeValue(value: boolean) {
    this.statusSource.next(value)
  }
    changeDateValue(value: boolean) {
    this.statusDateSource.next(value)
  }

  changeSelectedValue(value: []) {
    this.fromValue.next(value)
  }

  changeSelectedMobileValue(value: string) {
    this.fromValueMobile.next(value)
  }
}
