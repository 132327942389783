<div class=" hidden-md hidden-lg ">
    <app-nav ></app-nav>
</div>
<div class="visible-lg visible-md">
  <app-headerhome ></app-headerhome>
</div>
    <div id="container ">
        <div class="bus-booking-page searchFont">
            <div class="theme-hero-area theme-hero-area-full">
                <div class="theme-hero-area-bg-wrap">
                  <div class="theme-hero-area-bg" ></div>
                  <button id="openReloadButton"  [class.is-hidden]="isHidden" data-toggle="modal" data-target="#myModalReload">Open Modal</button>
                  <div class="theme-hero-area-inner-shadow"></div>
                </div>
                <div class="theme-hero-area-body theme-hero-area-body-vert-center">
                  <div class="container">
                    <div class="theme-loading">
                      <div class="row">
                        <div class="col-md-8 col-md-offset-2">
                            
                          <div class="spinner theme-loading-spinner">
                            <div class="spinner-grid-dots">
                                <img src="assets/img/flights/payment_loading.gif">
                            </div>
                          </div>
                          <div class="theme-loading-body">
                            <h2 class="theme-loading-title">Just a moment</h2>
                            <p class="theme-loading-subtitle">
                              <span *ngIf="isSaving" class="destinationHeading text-red">Saving your reservation....</span>
                              <span *ngIf="isWaiting" class="destinationHeading text-red">Processing your payments please wait.....</span>
                              <span *ngIf="!isWaiting" class="destinationHeading text-danger">{{ errorMessage }}</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <a class="theme-abs-logo" href="index.html">
                  <img src="img/logo.png" alt="Image Alternative text" title="Image Title"/>
                </a>
              </div>
        </div>
    </div>
    <!-- Modal -->
<div id="myModalReload" class="modal fade" role="dialog">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <button  id="closeConfirmDialog" type="button" class="close" data-dismiss="modal">&times;</button>
        <br>
        <div class="modal-body">
          <div class="facebook-login__disclaimer ">
            <p class="paragraphRegular">
              <span><i class="fas fa-exclamation-circle"></i></span> Sorry, we're having some issues confirming your payment. Try reloading the page.
            </p>
          </div>
          
          <div class="row">
            <div class="col-md-4 col-md-offset-4">
              <button (click)="backToHome()"  class="theme-search-area-cancel theme-search-area-submit-curved theme-search-area-submit-glow">
                <span >Cancel</span>
              </button>
            </div>
            <div class="col-md-4 ">
              <button (click)="retryConfirmation()"  class="theme-search-area-submit theme-search-area-submit-curved theme-search-area-submit-glow">
                <span >Reload</span>
              </button>
            </div>
          </div>
          
        </div>

      </div>

    </div>
  </div>
</div>
  <!-- Modal -->
<app-footer></app-footer>

    <!-- <div id="checkout-ebuupass" class="buupass-sandbox">

            <div id="checkout-ebuupass"
                class="buupass-context-popup buupass-overlay checkout-background-color-black buupass-logo-color-white">
                <a href="#" class="buupass-close" aria-label="close" role="button"></a>
                <div class="buupass-modal">
                    <div class="buupass-logo">
                            <div class="provider-info">

                                    <h4>Processing your payments please wait ....</h4>
                                    <div class="ixigo-logo u-ib "><img
                                            src="assets/img/greenline/mpesa-logo.png"></div>
                                    <div class="loader-img u-ib">
                                            <svg xmlns:svg="http://www.w3.org/2000/svg"
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink" version="1.0" width="152px" height="20px" viewBox="0 0 457 60" xml:space="preserve">
                                            <g>
                                                <circle fill="#007929" cx="-31" cy="30" r="30"/>
                                                <circle fill="#329353" cx="-97" cy="30" r="24"/>
                                                <circle fill="#64ae7d" cx="-163" cy="30" r="19"/>
                                                <circle fill="#c8e2d1" cx="-229.5" cy="30.5" r="13.5"/>
                                                <circle fill="#e1efe6" cx="-295" cy="31" r="11"/>
                                                <animateTransform attributeName="transform" type="translate" values="61 0;127 0;193 0;259 0;325 0;391 0;457 0;523 0;589 0;655 0;721 0;787 0;0 0;0 0;0 0;0 0;0 0;0 0;0 0;0 0;0 0;0 0;0 0;" calcMode="discrete" dur="1280ms" repeatCount="indefinite"/>
                                            </g>
                                            <g>
                                                <circle fill="#007929" cx="488" cy="30" r="30"/>
                                                <circle fill="#329353" cx="554" cy="30" r="24"/>
                                                <circle fill="#64ae7d" cx="620" cy="30" r="19"/>
                                                <circle fill="#c8e2d1" cx="686.5" cy="30.5" r="13.5"/>
                                                <circle fill="#e1efe6" cx="753" cy="31" r="11"/>
                                                <animateTransform attributeName="transform" type="translate" values="0 0;0 0;0 0;0 0;0 0;0 0;0 0;0 0;0 0;0 0;0 0;-61 0;-127 0;-193 0;-259 0;-325 0;-391 0;-457 0;-523 0;-589 0;-655 0;-721 0;-787 0;" calcMode="discrete" dur="1280ms" repeatCount="indefinite"/>
                                            </g>
                                        </svg>
                                    </div>
                                    <div class="provider-logo u-ib"><img class="u-v-align-middle "
                                            src="assets/img/logo.png"></div>
                                </div>

                    </div>
                    <div *ngIf="isError" class="buupass-message seat-book-error">{{errorMessage}}.&nbsp; </div>
                    <div *ngIf="isWaiting" class="buupass-message seat-book-waiting">{{waitingMessage}}.&nbsp; </div>
                    <div class="buupass-continue">
                            <button (click)="requestConfirm()" class="theme-search-area-submit _mt-0 theme-search-area-submit-no-border theme-search-area-submit-curved theme-search-area-submit-primary">CONFIRM</button>
                    </div>
                    <div class="buupass-loader">
                        <div class="buupass-spinner"></div>
                    </div>
                </div>
                <div class="buupass-iframe-container">
                    <div class="zoid-outlet"></div>
                </div>

            </div>
        </div> -->
